<!--
 * @Description: 我的购物车页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 01:55:47
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:42
 -->

<template>
  <div class="shoppingCart">
    <!-- 购物车头部 -->
    <div class="cart-header">
      <div class="cart-header-content">
        <p>
          <i class="el-icon-shopping-cart-full"></i>
          我的购物车
        </p>
        <span>温馨提示：产品是否购买成功，以最终下单为准哦，请尽快结算</span>
      </div>
    </div>
    <!-- 购物车头部END -->

    <!-- 购物车主要内容区 -->
    <div class="content" v-if="getShoppingCart.length > 0">
      <el-table
        :data="cartList"
        style="width: 100%"
        class="shoppingTable"
        :row-class-name="tableRowClassName"
      >
        <el-table-column width="60" label="全选" align="center">
          <template slot="header" slot-scope="scope">
            <el-checkbox class="returnCheck" :scope="scope" v-model="isAllCheck" title="全选"></el-checkbox
            >
          </template>
          <template slot-scope="{ row, $index }">
            <div>
              <span v-if="row.State == 0" style="color: #f56c6c">无效</span>
              <span v-else-if="row.State == 2" style="color: #f56c6c">限购</span>
              <el-checkbox
                v-else
                :value="row.check"
                @change="checkChange($event, $index)"
                :disabled="row.State == 0"
              ></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productImg" width="120">
          <template slot-scope="scope">
            <div class="pro-img">
              <!-- <div :class="['angle-box',{'isjd':scope.row.goodsFromType==1}]">{{scope.row.goodsFromType==1?'智选':'严选'}}</div> -->
              <router-link
                :to="{
                  path: '/goods/info',
                  query: { productID: scope.row.productID,search:scope.row.skuId },
                }"
              >
                <el-image :src="$target + scope.row.productImg"  style="width: 80px; height: 80px">
                  <div slot="error" class="image-slot">
                    <img
                      src="../assets/imgs/imgError.jpeg"
                      width="80px"
                      height="80px"
                    />
                  </div>
                </el-image>
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="productName"
          :show-overflow-tooltip="true"
          width="300"
        >
          <template slot-scope="{ row }">
            <div>
              <router-link
                :to="{
                  path: '/goods/info',
                  query: { productID: row.productID,search:row.skuId },
                }"

                target="_blank"
              >
                <span :class="['product-tag', { isjd: row.goodsFromType == 1 }]">{{ row.goodsFromType == 0 ? "严选" : "智选" }}</span>
                <span class="productName">{{ row.productName }}</span>
              </router-link>
              <div class="shop-code">
              商品编码：{{ row.skuId }}
              </div>    
            </div>             
          </template>          
        </el-table-column>
        <el-table-column
          label="商品属性"
          prop="productName"
          :show-overflow-tooltip="true"
          width="190"
        >
          <template slot-scope="{ row }">
            <div>
              <router-link
                :to="{
                  path: '/goods/info',
                  query: { productID: row.productID,search:row.skuId },
                }"
              >
                <span class="productName">{{ row.skuName }}</span>
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单价" prop="price" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.price | formatMoneySimple }}{{$currency}}</span>
          </template>
        </el-table-column>
        <el-table-column label="数量" width="150" align="center">
          <template slot-scope="{ row, $index }">
            <div class="pro-num">
              <el-input-number
                size="small"
                @change="handleChange($event, $index, row)"
                @click="subLoading=true"
                :value="row.num"
                :min="row.minNum > 0 ? row.minNum : 1"
                :max="row.maxNum == 0 ? 99999 : row.maxNum"
                :disabled="numDisabled || row.State == 0 || row.State == 2"
                :ref="'numInput' + $index"
              ></el-input-number></div
          ></template>
        </el-table-column>
        <el-table-column label="小计" align="center">
          <template slot-scope="{ row }">
            <div class="price">
              {{(row.price * row.num) | formatMoneySimple }}{{$currency}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div class="pro-action">
              <el-popover placement="right" :ref="`popover-${scope.row.id}`">
                <p>确定删除吗？</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button
                    type="primary"
                    size="mini"
                    :loading="delLoading"
                    @click="
                      deleteItem($event, scope.row.id, scope.row.productID)
                    "
                    >确定</el-button
                  >
                </div>
                <i
                  class="el-icon-error"
                  slot="reference"
                  style="font-size: 18px; cursor: pointer;"
                  title="删除"
                ></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="height: 20px; background-color: #f5f5f5"></div>
      <!-- 购物车底部导航条 -->
      <div class="cart-bar">
        <div class="cart-bar-left">
          <span class="btn-back">
            <router-link to="/goods">继续购物</router-link>
          </span>
          <span class="sep">|</span>
          <span class="cart-total">
            共
            <span class="cart-total-num">{{ getNum }}</span> 件商品，已选择
            <span class="cart-total-num">{{ getCheckNum }}</span> 件
          </span>
        </div>
        <div class="cart-bar-right">
          <span>
            <span class="total-price-title">合计：</span>
            <span class="total-price">{{ getTotalPrice | formatMoneySimple }}{{ $currency }}</span>
          </span>
          <router-link
            :to="getCheckNum > 0  ? '/confirmOrder' : ''"
          >
            <div
              :class="getCheckNum > 0 ? 'btn-primary' : 'btn-primary-disabled'"
            >
              去结算
            </div>
          </router-link>
        </div>
      </div>
      <!-- 购物车底部导航条END -->
    </div>
    <!-- 购物车主要内容区END -->

    <!-- 购物车为空的时候显示的内容 -->
    <div v-else class="cart-empty">
      <div class="empty">
        <h2>您的购物车还是空的！</h2>
        <router-link to="/">
          <p class="gohome">快去购物吧！</p>
        </router-link>
      </div>
    </div>
    <!-- 购物车为空的时候显示的内容END -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      delLoading: false,
      numDisabled: false,
      cartList: [],
      subLoading:false
    };
  },
  activated() {
    this.cartList = this.getShoppingCart;
  },
  watch: {
    getShoppingCart(e) {
      this.cartList = e;
    },
  },
  methods: {
    ...mapActions([
      "updateShoppingCart",
      "deleteShoppingCart",
      "checkAll",
      "getUserId",
    ]),
    inputBlur() {
      this.numDisabled = false;
    },
    tableRowClassName({ row, rowIndex }) {
      var name = "";
      if (row.State == 0 || row.State == 2)  {
        name = "car-row-disabled";
      }
      return name;
    },
    // 修改商品数量的时候调用该函数
    handleChange(currentValue, key, row) {
      this.numDisabled = true;
      
      // 向后端发起更新购物车的数据库信息请求
      const query = {
              CartID: row.id,
              GoodID: 1,
              numbers: currentValue,
            };      
      this.$axios
        .put("/api/Cart/ChangeCart", this.qs.stringify(query))
        .then((res) => { 
          //console.log(res);
           //修改失败         
          if (res.message) {             
              var numInput = this.$refs["numInput" + key];                    
              //不允许购买
              if(res.data==0)
              {
                this.updateShoppingCart({
                  key: key,
                  prop: "State",
                  val: 2,
                });

                //取消勾选
                this.updateShoppingCart({ key: key, prop: "check", val: false });

                numInput.userInput = row.num
              }
              else{
                //只能购买指定数量
                numInput.userInput =  res.data;
                //商品数量修改
                this.updateShoppingCart({key: key,prop: "num",val: res.data,});
              }

              //弹出提示
              this.notifyError(res.message);

          } 
          //修改成功
          else {    
            
            // 默认勾选
            this.updateShoppingCart({ key: key, prop: "check", val: true });
                  
            //商品数量修改用户填写值
            this.updateShoppingCart({key: key,prop: "num",val: currentValue,});
            // row.num=currentValue
            // this.notifySucceed("商品数量修改成功");
            // this.cartList=this.getShoppingCart
            // console.log(this.cartList,"---1111")
          }

          this.numDisabled = false;
          this.subLoading=false
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    checkChange(val, key) {
      // 更新vuex中购物车商品是否勾选的状态
      this.updateShoppingCart({ key: key, prop: "check", val: val });
    },
    // 向后端发起删除购物车的数据库信息请求
    deleteItem(e, id, productID) {
      this.delLoading = true;
      this.$axios
        .delete("/api/Cart/RemoveFromCart", {
          params: {
            ids: id,
          },
        })
        .then((res) => {
          if (res.data) {
            this.deleteShoppingCart(id);
            // this.notifySucceed("删除成功");
          } else {
            this.notifyError("删除失败，请稍后重试~~~");
          }
          this.delLoading = false;
          this.$refs["popover-" + id].doClose();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
  computed: {
    ...mapGetters([
      "getShoppingCart",
      "getCheckNum",
      "getTotalPrice",
      "getNum",
    ]),
    isAllCheck: {
      get() {
        return this.$store.getters.getIsAllCheck;
      },
      set(val) {
        this.checkAll(val);
      },
    },
  },
};
</script>
<style scoped>
.shoppingCart {
  background-color: #f7f7f7;
  padding-bottom: 20px;
  min-height: calc(100vh - 418px);
}
/* 购物车头部CSS */
.shoppingCart .cart-header {
  height: 64px;
  /* border-bottom: 2px solid #ff6701; */
  border-bottom: 2px solid var(--ThemeColor,#ff6701);
  background-color: #fff;
  margin-bottom: 20px;
}
.shoppingCart .cart-header .cart-header-content {
  width: 1225px;
  margin: 0 auto;
}
.shoppingCart .cart-header p {
  font-size: 28px;
  line-height: 58px;
  float: left;
  font-weight: normal;
  color: #424242;
}
.shoppingCart .cart-header span {
  color: #757575;
  font-size: 12px;
  float: left;
  height: 20px;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 15px;
}
/* 购物车头部CSS END */

/* 购物车主要内容区CSS */
.shoppingCart .content {
  width: 1225px;
  margin: 0 auto;
  background-color: #fff;
}
.shoppingCart .content .price {
  color: #ff6700;
}
.shoppingCart .content .productName {
  color: #424242;
}

.shoppingCart .content .product-tag {
  border-radius: 10px;
  background-color: #67c23a;
  color: #f5f5f5;
  padding: 0px 10px;
  font-size: 12px;
}
.shoppingCart .content .product-tag.isjd {
  background-color: #ff5933;
}
.pro-img {
  height: 80px;
  width: 80px;
  position: relative;
  overflow: hidden;
}
.pro-img .angle-box {
  color: #fff;
  height: 30px;
  width: 150px;
  line-height: 40px;
  position: absolute;
  right: -100px;
  top: -50px;
  background-color: #67c23a;
  text-align: center;
  transform-origin: left top;
  transform: rotate(45deg);
  font-size: 12px;
  z-index: 100;
}
.pro-img .angle-box.isjd {
  background-color: #ff5933;
}
.el-table >>> .car-row-disabled {
  /* opacity: 0.5; */
  background-color: #f3f3f3;
}
/* 购物车表头及CSS END */

/* 购物车底部导航条CSS */
.shoppingCart .cart-bar {
  width: 1225px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
}
.shoppingCart .cart-bar .cart-bar-left {
  float: left;
}
.shoppingCart .cart-bar .cart-bar-left a {
  line-height: 50px;
  /* margin-left: 32px; */
  color: #757575;
}
.shoppingCart .cart-bar .cart-bar-left a:hover {
  /* color: #ff6700; */
  color:var(--ThemeColor,#ff6701);
}
.shoppingCart .cart-bar .cart-bar-left .btn-back{
    background-color: #e0e0e0;
    width: 120px;
    padding: 15px 27px;
    text-align: center;
}

.shoppingCart .cart-bar .cart-bar-left .sep {
  color: #eee;
  margin: 0 20px;
}
.shoppingCart .cart-bar .cart-bar-left .cart-total {
  color: #757575;
}
.shoppingCart .cart-bar .cart-bar-left .cart-total-num {
  color: #ff6700;
}
.shoppingCart .cart-bar .cart-bar-right {
  float: right;
}
.shoppingCart .cart-bar .cart-bar-right .total-price-title {
  color: var(--ThemeColor,#ff6701);
  font-size: 14px;
}
.shoppingCart .cart-bar .cart-bar-right .total-price {
  color: var(--ThemeColor,#ff6701);
  font-size: 30px;
}
.shoppingCart .cart-bar .cart-bar-right .btn-primary {
  float: right;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-left: 50px;
  /* background: #ff6701; */
  color: #fff;
  background:var(--ThemeColor,#ff6701);
}
.shoppingCart .cart-bar .cart-bar-right .btn-primary-disabled {
  float: right;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-left: 50px;
  background: #e0e0e0;
  color: #b0b0b0;
}
.shoppingCart .cart-bar .cart-bar-right .btn-primary:hover {
  /* background-color: #f25807; */
   text-decoration:underline;
}
/* 购物车底部导航条CSS END */
/* 购物车主要内容区CSS END */

/* 购物车为空的时候显示的内容CSS */
.shoppingCart .cart-empty {
  width: 1225px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 508px);
}
.shoppingCart .cart-empty .empty {
  height: 300px;
  padding: 0 0 130px 558px;
  margin: 65px 0 0;
  background: url(../assets/imgs/cart-empty.png) no-repeat 124px 0;
  color: #b0b0b0;
  overflow: hidden;
}
.shoppingCart .cart-empty .empty h2 {
  margin: 70px 0 15px;
  font-size: 36px;
}
.shoppingCart .cart-empty .empty p {
  margin: 0 0 20px;
  font-size: 20px;
  color: #b0b0b0;
}
/* 购物车为空的时候显示的内容CSS END */


.shop-code{
  margin-left: 10px; 
    padding-top: 10px;
    color: #6a6565b8;
    font-size: 12px;
}
.el-icon-shopping-cart-full{
  /* color: #ff6701;  */
  font-weight: 600;
  color:var(--ThemeColor,#ff6701);
}

</style>